import React, { Component, Redirect } from 'react';
import { CategorySelector } from '../CategorySelector/CategorySelector';
import { useNavigate } from "react-router-dom";
import ImageUpload from './ImageUpload';
import { ManufacturerSelector } from '../ManufacturerSelector/ManufacturerSelector';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Spinner } from 'reactstrap';

export class ItemCreationForm extends Component {



    constructor(props) {
        super(props);
        this.state = {
            name: "",
            description: "",
            categoryId: null,
            manufacturerId: null,
            measurementUnit: "Item",
            dontTrack: false,
            defaultCreationQuantity: 1,
            alertOnLowStock: false,
            minimumQuantity: 0,
            loading: true,

            formSubmitted: false, buttonText: "Submit", redirect_page: false, redirect_id: 0, uploadedImage: "", showMinimumStockTextbox: false, lowStockChecked: false
        };
    };

    static defaultProps = {
        onSelection: () => { },
        editItemListingId: null
    };

    componentDidMount() {
        if (this.props.editItemListingId !== null) {
            this.loadItem();
        }        
    }

    async loadItem() {
        const response = await fetch(`api/items/get-item?itemListingId=${this.props.editItemListingId}`);
        const data = await response.json();

        this.setState({ ...data, showMinimumStockTextbox: true, loading:false });
    }

    onCategorySelection = (id) => {
        this.setState({
            categoryId: id
        });
    }

    onManufacturerSelection = (id) => {
        this.setState({
            manufacturerId: id
        });
    }

    onImageUpload = (image) => {
        this.setState({
            uploadedImage: image
        });
    }

    onSubmit = (event) => {
        event.preventDefault(event);
        this.sendCreateCategory(event.target);
    };

    onChangeLowStockEnable = (event) => {
        this.setState({ showMinimumStockTextbox: event.target.checked, lowStock: event.target.checked });
    };

    onChangeDontTrack = (event) => {
        this.setState({ showMinimumStockTextbox: this.state.lowStock && !event.target.checked, lowStock: event.target.checked || this.state.lowStock });
    };

    onChangeMeasurementUnit = (event) => {
        this.setState({ measurementUnit: event.target.value });
    };


    async sendCreateCategory(form) {
        this.setState({
            buttonText: "Please wait..."
        });
        
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            
            body: JSON.stringify({
                id: this.props.editItemListingId === null ? 0 : this.props.editItemListingId,
                name: form.name.value,
                description: form.description.value,
                categoryId: this.state.categoryId,
                manufacturerId: this.state.manufacturerId,
                measurementUnit: form.measurement_unit.value,
                dontTrack: form.dontTrack.checked,
                defaultCreationQuantity: form.default_creation_quantity.value,
                alertOnLowStock: form.alertOnLowStock.checked,
                minimumQuantity: form.minimumQuantity.value,
                customLink1Name: form.customLink1Name.value,
                customLink1Url: form.customLink1Url.value,
                customLink2Name: form.customLink2Name.value,
                customLink2Url: form.customLink2Url.value,
                customLink3Name: form.customLink3Name.value,
                customLink3Url: form.customLink3Url.value,                                                
                imageData: form.imagedata.value,
                imageId: this.state.imageId
            })
        };

        var fetchURL = 'api/items/create';

        if (this.props.editItemListingId !== null) {
            fetchURL = "api/items/update";            
        }

        const response = await fetch(fetchURL, requestOptions);
        const data = await response.json();

        this.setState({
                redirect_page: true,
                redirect_id: data.id
            });
    }

    render() {
        if (this.props.editItemListingId !== null && this.state.loading) {
            return (<center><Spinner as="span" variant="primary" animation="border" size="lg" role="status" aria-hidden="true" /></center>);
        }
        else {
            return (
                <form onSubmit={this.onSubmit}>
                    <RedirectPage redirect={this.state.redirect_page} itemID={this.state.redirect_id} />
                    <h3>{this.props.editItemListingId === null ? <center> Create a new item listing</center> : <center> Edit listing</center>} </h3>
                    <div className="item-create-form-group">
                        <label htmlFor="name">Name</label>
                        <input className="form-control" id="name" defaultValue={this.state.name} required />
                    </div>
                    <div className="item-create-form-group">
                        <label htmlFor="description">Description</label>
                        <textarea className="form-control" id="description" defaultValue={this.state.description} />
                    </div>
                    <div className="item-create-form-group">
                        <label htmlFor="parent_category">Category</label>
                        <CategorySelector onSelection={this.onCategorySelection} defaultValue={this.state.categoryId} allowCreate={true} />
                    </div>
                    <div className="item-create-form-group">
                        <label htmlFor="manufacturer">Manufacturer / Product family</label>
                        <ManufacturerSelector onSelection={this.onManufacturerSelection} defaultValue={this.state.manufacturerId} allowCreate={true} />
                    </div>
                    <div className="item-create-form-group">
                        <label htmlFor="measurement_type">Quantity units</label>
                        <select className="form-control" id="measurement_unit" value={this.state.measurementUnit} onChange={this.onChangeMeasurementUnit}>
                            <option value="Individual">Individually barcoded item</option>
                            <option value="Item" >Each (allow a bin of items)</option>
                            <option value="Metres">Length - Metres (consumable)</option>
                            <option value="Grams">Weight - Grams (consumable)</option>
                        </select>
                    </div>
                    <div className="item-create-form-group" style={{ paddingTop: "10px" }}>
                        <label htmlFor="default_creation_quantity">Default creation quantity (units / metres)</label>
                        <input className="form-control" pattern="\d+\.?\d*" defaultValue={this.state.defaultCreationQuantity} id="default_creation_quantity" required />
                    </div>
                    <div className="item-create-form-group" style={{ paddingTop: "10px" }}>
                        <div className="form-check form-switch">
                            <input className="form-check-input" type="checkbox" id="dontTrack" onChange={this.onChangeDontTrack} defaultChecked={this.state.dontTrack} /><label htmlFor="dont_track">Don't track stock levels for this item</label>
                        </div>
                    </div>
                    <div className="item-create-form-group" style={{ paddingTop: "10px" }}>
                        <div className="form-check form-switch">
                            <input className="form-check-input" type="checkbox" id="alertOnLowStock" onChange={this.onChangeLowStockEnable} checked={this.state.lowStock} /><label htmlFor="low_stock">Enable low stock alert threshold</label>
                        </div>
                        {this.state.showMinimumStockTextbox ?
                            <div>
                                <input className="form-control" pattern="\d+\.?\d*" id="minimumQuantity" placeholder="Stock alert threshold level" type="number" min="0" defaultValue={this.state.minimumQuantity} required />
                            </div>
                            :
                            <input type="hidden" id="minimumQuantity" value="0" />
                        }
                    </div>
                    <div className="item-create-form-group">
                        <label htmlFor="name">Custom Links</label>
                        <div className="row">
                            <div className="col">Link #1 Name: <input className="form-control" id="customLink1Name" defaultValue={this.state.customLink1Name} />Link #1 URL: <input className="form-control" id="customLink1Url" defaultValue={this.state.customLink1Url} /></div>
                            <div className="col">Link #2 Name: <input className="form-control" id="customLink2Name" defaultValue={this.state.customLink2Name} />Link #2 URL: <input className="form-control" id="customLink2Url" defaultValue={this.state.customLink2Url} /></div>
                            <div className="col">Link #3 Name: <input className="form-control" id="customLink3Name" defaultValue={this.state.customLink3Name} />Link #3 URL: <input className="form-control" id="customLink3Url" defaultValue={this.state.customLink3Url} /></div>
                        </div>
                    </div>
                    <div className="item-create-form-group" style={{ paddingTop: "10px" }}>
                        <label htmlFor="imagedata">Image of item</label>
                        <ImageUpload onImageUpload={this.onImageUpload} />
                        <input type="hidden" id="imagedata" value={this.state.uploadedImage} />
                    </div>
                    <div className="item-create-form-group" style={{ paddingTop: "10px", width: "300px", alignContent: "center" }} >
                        <button disabled={this.state.formSubmitted || !(this.state.categoryId !== null)} className="item-create-form-group form-control btn btn-primary" type="submit">
                            {!(this.state.categoryId !== null) ? "Cannot submit, category empty" : this.state.buttonText}
                        </button>
                    </div>
                </form>
            );
        }        
    }
}

function RedirectPage(props) {
    let navigate = useNavigate();
    if (props.redirect) {
        const url = "/item/view/" + props.itemID;
        navigate(url, { replace: true });
    }
    return null;
}
