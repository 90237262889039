import React, { Component, Redirect } from 'react';
import { SupplierSelector } from '../SupplierSelector/SupplierSelector';
import { useNavigate } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Spinner } from 'reactstrap';

export class OrderDetailsForm extends Component {

    //ItemID,Supplier,ModelName,ModelNumber,Url,ApproximatePrice,MinOrderQuantity,Notes,ApproximateLeadTime
    //ItemID,Supplier,Supplier Model Name,Supplier Model Number,Url,Approximate Price,Min. Order Quantity,Notes,Approximate Lead Time
    //itemid,supplier,modelname,modelnumber,url,approximateprice,minorderquantity,notes,approximateleadtime

    constructor(props) {
        super(props);
        this.state = {
            selectedId: null, formSubmitted: false, buttonText: "Submit", redirect_page: false, loading: true, currentOrderInformation: { itemListingId: this.props.itemListingId }
        };
    };

    static defaultProps = {
        onSelection: () => { },
        editOrderDetailsId: null,
        itemListingId: null
    };

    componentDidMount() {
        if (this.props.editOrderDetailsId !== null) {
            this.loadOrderDetails();
        }
    }

    async loadOrderDetails() {
        const response = await fetch(`api/ordering/get-ordering-details?orderDetailsId=${this.props.editOrderDetailsId}`);
        const data = await response.json();

        this.setState({ currentOrderInformation: data, selectedSupplierId: data.supplierId, loading: false });
    }

    onSupplierSelection = (id) => {
        this.setState({
            selectedSupplierId: id
        });
    };
                 
    onSubmit = (event) => {
        event.preventDefault(event);
        this.sendCreateCategory(event.target);
    };

    async sendCreateCategory(form) {
        this.setState({
            buttonText: "Please wait..."
        });
        
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            
            body: JSON.stringify({
                id: this.props.editOrderDetailsId === null ? 0 : this.props.editOrderDetailsId,
                itemListingId: this.state.currentOrderInformation.itemListingId,
                supplierId: this.state.selectedSupplierId,
                modelName: form.ModelName.value,
                supplierModelNumber: form.SupplierModelNumber.value,
                manufacturerModelNumber: form.ManufacturerModelNumber.value,
                url: form.Url.value,
                approximatePrice: form.ApproximatePrice.value,
                minOrderQuantity: form.MinOrderQuantity.value,
                notes: form.Notes.value,
                approximateLeadTime: form.ApproximateLeadTime.value
            })
        };


        var fetchURL = 'api/ordering/create-item-ordering-details';

        if (this.props.editOrderDetailsId !== null) {
            fetchURL = "api/ordering/update-item-ordering-details";
        }

        const response = await fetch(fetchURL, requestOptions);
        const data = await response.json();

        this.setState({
                redirect_page: true
            });
    }

    render() {
        if (this.props.editOrderDetailsId !== null && this.state.loading) {
            return (<center><Spinner as="span" variant="primary" animation="border" size="lg" role="status" aria-hidden="true" /></center>);
        }
        else {
            return (
                <form onSubmit={this.onSubmit}>
                    <RedirectPage redirect={this.state.redirect_page} itemListingId={this.state.currentOrderInformation.itemListingId} />

                    <h3><center>Item Ordering Information:</center></h3>

                    <div className="item-create-form-group">
                        <label htmlFor="supplier">Supplier</label>
                        <SupplierSelector onSelection={this.onSupplierSelection} defaultValue={this.state.selectedSupplierId} allowCreate={true} />
                    </div>
                    <div className="item-create-form-group">
                        <label htmlFor="modelname">Supplier Model Name</label>
                        <input className="form-control" id="ModelName" defaultValue={this.state.currentOrderInformation.modelName} required />
                    </div>
                    <div className="item-create-form-group">
                        <label htmlFor="modelnumber">Supplier's Model Number</label>
                        <input className="form-control" id="SupplierModelNumber" defaultValue={this.state.currentOrderInformation.supplierModelNumber} required />
                    </div>
                    <div className="item-create-form-group">
                        <label htmlFor="modelnumber">Manufacturer's Model Number</label>
                        <input className="form-control" id="ManufacturerModelNumber" defaultValue={this.state.currentOrderInformation.manufacturerModelNumber} required />
                    </div>
                    <div className="item-create-form-group">
                        <label htmlFor="url">Url</label>
                        <input className="form-control" id="Url" defaultValue={this.state.currentOrderInformation.url} required />
                    </div>
                    <div className="item-create-form-group">
                        <label htmlFor="approximateprice">Approximate Price (&#163;)</label>
                        <input className="form-control" id="ApproximatePrice" pattern="\d+\.?\d*" defaultValue={this.state.currentOrderInformation.approximatePrice} required />
                    </div>
                    <div className="item-create-form-group">
                        <label htmlFor="minorderquantity">Min. Order Quantity</label>
                        <input className="form-control" id="MinOrderQuantity" pattern="\d+\.?\d*" defaultValue={this.state.currentOrderInformation.minOrderQuantity} required />
                    </div>
                    <div className="item-create-form-group">
                        <label htmlFor="notes">Notes</label>
                        <textarea className="form-control" id="Notes" defaultValue={this.state.currentOrderInformation.notes} />
                    </div>
                    <div className="item-create-form-group">
                        <label htmlFor="approximateleadtime">Approximate Lead Time</label>
                        <input className="form-control" id="ApproximateLeadTime" defaultValue={this.state.currentOrderInformation.approximateLeadTime} required />
                    </div>

                    <div className="item-create-form-group" style={{ paddingTop: "10px", width: "300px", alignContent: "center" }} >
                        <button disabled={this.state.formSubmitted || !(this.state.selectedSupplierId > 0)} className="item-create-form-group form-control btn btn-primary" type="submit">
                            {!(this.state.selectedSupplierId > 0) ? "Cannot submit, supplier empty" : this.state.buttonText}
                        </button>
                    </div>
                </form>
            );
        }
    }
}

function RedirectPage(props) {
    let navigate = useNavigate();
    if (props.redirect) {
        var url = "/item/view/" + props.itemListingId
        navigate(url, { replace: true });
    }
    return null;
}
